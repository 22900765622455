/*-----------------------------------------------------------------------------------

    Template Name: Eliza -  eCommerce Bootstrap 4 Template
    Version: 1

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Banner style
    5. Deal style
    6. Section-title style
    7. Product style
    8. Testimonial style
    9. Blog style
    10. Instagram style
    11. Footer style
    12. Feature style
    13. Brand-logo style
    14. Video style
    15. Breadcrumb style
    16. Sidebar style
    17. Shop style
    18. Cart style
    19. Login register style
    20. My account style
    21. Product-details style
    22. Blog-details style
    23. Checkout style
    24. Contact-us style
    25. Compare style
    26. About style
    
-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common';
@import 'header';
@import 'slider';
@import 'banner';
@import 'deal';
@import 'section-title';
@import 'product';
@import 'testimonial';
@import 'blog';
@import 'instagram';
@import 'footer';
@import 'feature';
@import 'brand-logo';
@import 'video';
@import 'breadcrumb';
@import 'sidebar';
@import 'shop';
@import 'cart';
@import 'login-register';
@import 'my-account';
@import 'product-details';
@import 'blog-details';
@import 'checkout';
@import 'contact-us';
@import 'compare';
@import 'about';













